<template>
  <div class="MainStepsForm">
    <div v-if='step==1' class="container">
      <a-alert message="平台为你自行上传的作品进行版权登记，不对您上传内容的真实性进行验证" type="warning" show-icon />
      <div class="commonWhiteBg">
        <!-- <div class="ant-popover-arrow"></div> -->
        <div class="commonTitle">版权登记</div>
        <div class="formWrap registerFormWarp">
          <el-form ref="form" :model="form" :rules="rules" label-width="250px">
            <!-- 第二步 -->
            <div>
              <el-form-item label="作品名称" prop="subject">
                <el-input placeholder="请输入作品名称" v-model="form.subject"></el-input>
              </el-form-item>
              <el-form-item label="作品类型" prop="worksType">
                <el-select
                  v-model="worksTypeText"
                  placeholder="请选择创作类型"
                  @change="selectWorksType"
                >
                  <el-option
                  v-for="(item, index) in constants.worksType"
                  :key="index"
                  :label="item.label"
                  :value="index"
                  >
                  </el-option>
                </el-select>
                <!-- workCategory -->
                <a-alert class="tips" type="warning" v-if="form.worksType == '1'">
                      <div slot="description" >
                        文字：小说、诗词、散文、论文等以文字形式表现的作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '2'">
                      <div slot="description" >
                        口述：即兴的演说、授课、法庭辩论等以口头语言形式表现的作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '3'">
                      <div slot="description" >
                        音乐：歌曲、交响乐等能够演唱或者演奏的带词或者不带词的作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '4'">
                      <div slot="description" >
                        戏剧：话剧、歌剧、地方戏等供舞台演出的作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '5'">
                      <div slot="description" >
                        曲艺：相声、快书、大鼓、评书等以说唱为主要形式表演的作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '6'">
                      <div slot="description" >
                        舞蹈：通过连续的动作、姿势、表情等表现思想情感的作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '7'">
                      <div slot="description" >
                        杂技：杂技、魔术、马戏等通过形体动作和技巧表现的作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '8'">
                      <div slot="description" >
                        美术：绘画、书法、雕塑等以线条、色彩或者其他方式构成的有审美意义的平面或者立体的造型艺术作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '9'">
                      <div slot="description" >
                        建筑：以建筑物或者构筑物形式表现的有审美意义的作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '10'">
                      <div slot="description" >
                        摄影：借助器械在感光材料或者其他介质上记录客观物体形象的艺术作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '11'">
                      <div slot="description" >
                        电影：是指摄制在一定介质上，由一系列有伴音或者无伴音的画面组成，并且借助适当装置放映或者以其他方式传播的作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '12'">
                      <div slot="description" >
                        类似摄制电影方法创作的作品：是指摄制在一定介质上，由一系列有伴音或者无伴音的画面组成，并且借助适当装置放映或者以其他方式传播的作品（电视剧，动画片，视频，录音、录像制品等都归于这一类）。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '13'">
                      <div slot="description" >
                        工程设计图、产品设计图：为施工、生产绘制的工程设计图、产品设计图，以及反映地理现象、说明事物原理或者结构的地图、示意图等作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '14'">
                      <div slot="description" >
                        地图、示意图：为施工、生产绘制的工程设计图、产品设计图，以及反映地理现象、说明事物原理或者结构的地图、示意图等作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '15'">
                      <div slot="description" >
                        模型：为展示、试验或者观测等用途，根据物体的形状和结构，按照一定比例制成的立体作品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '16'">
                      <div slot="description" >
                        录音制品：任何对表演的声音和其他声音的录制品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '17'">
                      <div slot="description" >
                        录像制品：电影作品和以类似摄制电影的方法创作的作品以外的任何有伴音或无伴音的连续相关形象、图像的制品。
                      </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.worksType == '18'">
                      <div slot="description" >
                        其他：根据法律法规规定的其他作品。选择“其他”类时，请说明具体情况。
                      </div>
                </a-alert>
              </el-form-item>
              <el-form-item label="创作性质" prop="creationNature">
                <el-select
                    v-model="creationNatureText"
                    placeholder="请选择作品创作性质"
                    @change="selectCreationNature"
                >
                    <el-option
                    v-for="(item, index) in constants.creationNature"
                    :key="index"
                    :label="item.label"
                    :value="index"
                    >
                    </el-option>
                </el-select>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '1'">
                    <div slot="description">
                    作者未参考或使用已存在作品创作的作品。
                    </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '2'">
                    <div slot="description">
                    作者参考已存在作品衍生创作的作品。
                    </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '3'">
                    <div slot="description">
                    作者参考或使用已存在的外语作品衍生创作的作品。
                    </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '4'">
                    <div slot="description">
                    作者参考或使用已存在作品衍生创作的系列。
                    </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '5'">
                    <div slot="description">
                    作者使用已存在作品衍生创作的作品。
                    </div>
                </a-alert>
                <a-alert class="tips" type="warning" v-if="form.creationNature == '6'">
                    <div slot="description">
                    作者参考或使用已存在作品衍生创作的合集。
                    </div>
                </a-alert>
                <!-- <a-alert class="tips" type="warning" v-if="form.creationNature == '7'">
                    <div slot="description">
                    请添加作品性质说明。
                    </div>
                </a-alert> -->
              </el-form-item>
            </div>
            <!-- 第三步 -->
            <div>
                <el-form-item label="内容简介" prop="creationIntention.text" style="margin-bottom: 50px;">
                <el-input
                    type="textarea"
                    :rows="6"
                    placeholder="请输入内容简介"
                    v-model="form.creationIntention.text"
                ></el-input>
                <a-alert class="tips" type="warning">
                    <div slot="message">温馨提示：</div>
                    <div slot="description">
                      请填写作品中心内容（不超过50字）。
                    </div>
                </a-alert>
                </el-form-item>
                <el-form-item label="创作过程" prop="creationOriginality.text">
                <el-input
                    type="textarea"
                    :rows="6"
                    placeholder="请输入创作过程"
                    v-model="form.creationOriginality.text"
                ></el-input>
                <a-alert class="tips" type="warning">
                    <div slot="message">温馨提示：</div>
                    <div slot="description">
                      (1)介绍著作权人。个人：需交代工作单位相关情况，无工作写明“无工作单位，现从事……”。单位：写明单位基本情况。（2）创作过程。完整介绍创作意图、策划准备、开始时间、修改情况及内容、创作方式（手写、电脑软件等）、页数字数、完成时间。（3）介绍使用情况：发表情况（发表日期、发表地点等）。如作品有参考引用他人作品需交代说明。（不超过85字）
                    </div>
                </a-alert>
                </el-form-item>
                <el-form-item label="创作完成时间" prop="finishTime">
                <el-date-picker
                    v-model="form.finishTime"
                    placeholder="选择日期时间"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="timestamp"
                    :picker-options="pickerOptionsStart"
                >
                </el-date-picker>
                </el-form-item>
                <el-form-item label="创作完成地点" prop="province">
                  <china-area-data :areaData="areaData1" @change="selectArea"></china-area-data>
                </el-form-item>
                <el-form-item label="作品发表状态" prop="publishStatus">
                <el-select
                    v-model="publishStatusText"
                    placeholder="请选择作品发表状态"
                    @change="selectPublishStatus"
                >
                    <el-option
                    v-for="(item, value) in constants.publishStatus"
                    :key="value"
                    :label="item.label"
                    :value="value"
                    >
                    </el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="首次发表日期" prop="publishTime" v-if="form.publishStatus == 2">
                <el-date-picker
                    v-model="form.publishTime"
                    placeholder="选择日期时间"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="timestamp"
                    :picker-options="pickerOptionsEnd"
                >
                </el-date-picker>
                </el-form-item>
                <el-form-item label="发表地点" prop="province2" v-if="form.publishStatus == 2">
                <china-area-data :areaData="areaData2" @change="selectArea2"></china-area-data>
                </el-form-item>
            </div>
            <!-- 第四步 -->
            <div>
                
                <el-form-item
                label="上传作品"
                ref="uploader"
                class="uploadWraper"
                prop="files"
                >
                <el-upload
                    class="commonUpload"
                    drag
                    :action="uploadUrl"
                    :headers="uploadHeaders"
                    :on-success="uploadSuccess"
                    :on-remove="(file, fileList) => {handleRemove(file, fileList)}"
                    :before-upload="uploadBefore"
                    multiple
                    :limit = '1'
                    :on-exceed="handleExceed"
                    :file-list="fileList"
                >
                    <div class="commonUploadInner">
                    <i class="iconfont iconUpload">&#xe606;</i>
                    <div class="el-upload__text">
                        <div>将文件拖到此处，或<em>点击上传</em></div>
                        <div>支持扩展名：.doc .docx .txt .pdf .jpg...</div>
                    </div>
                    </div>
                </el-upload>
                <a-alert class="tips" type="warning">
                    <div slot="message">温馨提示：</div>
                    <div slot="description">
                    <!-- 1、同一批的存证文件归属于同一标签，不支持重置；<br> -->
                    1、默认格式:  doc | docx | txt | pdf | jpg | png | mp3 | mp4 ...<br>
                    2、单文件大小: 不超过100M，一次只能上传1个文件， 作品名称、作品电子文件名称、作品电子文件内作品名称须保持一致。 <br>
                    </div>
                </a-alert>
                </el-form-item>
            </div>
            <!-- 第五步 -->
            <div>
              <el-form-item
                label="权利保证书"
                prop="ownershipPicture.additionalProp4"
                :rules="{ required: true, validator: valiIcon4, trigger: 'blur' }"
              >
              <div class="uploadBusinessLicense" >
                      <el-checkbox v-model="ownershipChecked" @change="ownershipChange"></el-checkbox> 我已阅读并同意<a href="javascript:;" @click="openViewer2">《权利保证书》</a>
                      <el-image-viewer
                        v-if="isOpenViewer"
                        :on-close="closeViewer"
                        :url-list="[require('../../../assets/images/evidenceRight.png')]"
                      />
                      <div class="clear"></div>
                  </div>
                <a-alert class="tips" type="warning">
                  <div slot="message">温馨提示：</div>
                  <div slot="description">
                    <!-- <a
                      href="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql7.docx"
                      download="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-ql7.docx"
                      >权利保证书模板</a>请上传权利保证书(作品)，须亲笔签名或盖章确认。 -->
                      点击预览<span href="" style="color: #007DFF;cursor: pointer;" @click="openViewer">《作品登记权利保证书》</span>
                  </div>
                </a-alert>
              </el-form-item>

              <el-form-item label="支付方式" prop="paymode" class="choosePayStyle">
                <el-radio-group v-model="form.paymode">
                  <el-radio label="wechat">  <i class="iconfont weChatColor"> &#xe678; </i> <span>微信支付</span></el-radio>
                  <el-radio label="alipay"> <i class="iconfont aliPayColor"> &#xe675; </i> <span>支付宝支付</span></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="支付金额" v-if="form.paymode != 'vip'" class="payMount">
                <div class="amount">
                  <p>¥ {{ form.orderAmount }} </p>
                </div>
              </el-form-item>
               
              <el-form-item class="submitRow">
                <!-- <el-button type="primary" @click="prev">上一步</el-button> -->
                <el-button type="primary" @click="showAgree">下一步</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <div class="agree" v-if="isShowAgree">
      <div class="agreeBox">
        <h2>权利保证书</h2>
        <p>本人（单位）保证所申请登记的如下作品（制品）的权利归本人（单位）所有，保证提交的文件真实、合法。如有不实，本人愿承担一切法律责任。</p>
        <p>说明：凡申请作品自愿登记者，均须签署如上权利保证书。如系剽窃、抄袭他人作品进行登记以及提交的文件不真实、不合法，除应承担相应的法律责任外，著作权行政管理部门还将视情况给予行政处罚。</p>
        <el-button type="primary" :disabled="state" @click="next">下一步<span v-if="state"> ({{stateNum}})</span></el-button>
      </div>
    </div>
    <div class="agree" v-if="isShowAgree2">
      <div class="agreeBox">
        <h2>权利保证书</h2>
        <p>本人（单位）保证所申请登记的如下作品（制品）的权利归本人（单位）所有，保证提交的文件真实、合法。如有不实，本人愿承担一切法律责任。</p>
        <p>说明：凡申请作品自愿登记者，均须签署如上权利保证书。如系剽窃、抄袭他人作品进行登记以及提交的文件不真实、不合法，除应承担相应的法律责任外，著作权行政管理部门还将视情况给予行政处罚。</p>
        <el-button type="primary" :disabled="state" @click="closeShowAgree2">确定<span v-if="state"> ({{stateNum}})</span></el-button>
      </div>
    </div>

    <!-- 整理提交表单页面 -->
    <result-view v-if='step==6' @getnext='getnext' :orderInfo="form"></result-view>
    <!-- <result-view
      v-if="tabIdx == 'tab2'"
      :orderInfo="form"
      @set-tab-idx="setTabIdx"
    >
    </result-view> -->
  </div>
</template>

<script>
import { queryExistOrderItems } from "@/api/EvidenceService";
import { queryObligeeList,queryEvidenceRight } from "@/api/ObligeeService";
// import Steps from "../../components/Steps";
import multipleSelect from "../../../components/form/multipleSelect";
import choosePersonDialog from "../../../components/form/choosePersonDialog";
import resultView from "./components/resultView";
import ChinaAreaData from "@/components/ChinaAreaData";
import { mapGetters } from "vuex";
import { mixinsScrollErrorPosition } from "../../../mixins";
import constants from "@/utils/constants";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

import Step1 from "./components/step1.vue";
import Step2 from "./components/step2.vue";
import Step3 from "./components/step3.vue";
import Step4 from "./components/step4.vue";
import Step5 from "./components/step5.vue";

export default {
  name: "RegisterInputForm",
  mixins: [mixinsScrollErrorPosition],
  components: {
    // Steps,
    multipleSelect,
    choosePersonDialog,
    resultView,
    ChinaAreaData,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    ElImageViewer,
  },
  data() {
    return {
      constants,
      step: 1,
      worksTypeText:'',
      creationNatureText: '',
      publishStatusText: '',
      form: {
        paymode: 'wechat',
        right: [],
        creationNature: "",
        creationPropertyDesc:{
          text:"",
        },
        finishTime: "",
        creationIntention:{
          text:"",
        },
        creationOriginality:{
          text:"",
        },
        province: "",
        city: "",
        publishStatus: "",
        province2: "",
        city2: "",
        publishTime: "",
        subject: "",
        worksType: "",
        files: [],
        ownership: "1",
        obtainType: "1",
        authors: [
          {name:"",signatureType:"1",signatureName:"",orders:1},
        ],
        obtainTypeDesc: {
          text:"",
        },
        ownershipPicture: {
          "additionalProp1": "",
          "additionalProp2": "",
          "additionalProp3": "",
          "additionalProp4": "",
        },
        rightHave: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17"],
        orderAmount: '0.00',
      },
      rules: {
        subject: [
          { required: true, message: "请输入作品名称", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        worksType: [
          { required: true, message: "请选择作品创作类型", trigger: "blur" },
        ],
        creationNature:[
          { required: true, message: "请选择作品创作性质", trigger: "blur" },
        ],
        finishTime: [
          { required: true, message: "请选择创作完成时间", trigger: "blur" },
        ],
        'creationIntention.text': [
          { required: true, validator: this.valiText1, trigger: "blur" },
        ],
        'creationOriginality.text': [
          { required: true, validator: this.valiText2, trigger: "blur" },
        ],
        province: [
          { required: true, validator: this.valiCity, trigger: "blur" },
        ],
        publishStatus: [
          { required: true, message: "请选择作品发表状态", trigger: "blur" },
        ],
        publishTime: [
          { required: true, message: "请选择首次发表日期", trigger: "blur" },
        ],
        province2: [
          { required: true, validator: this.valiCity2, trigger: "blur" },
        ],
        files: [
          { required: true, message: "请上传作品", trigger: "blur" },
        ],
        ownership: [
          { required: true, message: "请选择权利权属", trigger: "blur" },
        ],
      },
      isAuthorDialogVisible: false,
      isRightDialogVisible: false,

      //完成日期在发表日期之前,同时不能超过当前日期
      pickerOptionsStart: {
          disabledDate: time => {
              return time.getTime() > new Date(this.form.publishTime).getTime() || time.getTime() > Date.now()   //-8.64e7可以让开始时间和结束时间选同一天
          }
      },
      pickerOptionsEnd: {
          disabledDate: time => {
              return time.getTime() < new Date(this.form.finishTime).getTime() || time.getTime() > Date.now()     //-8.64e7可以让开始时间和结束时间选同一天
          }
      },

      uploadUrl:`${process.env.VUE_APP_BASEURL}/v1/ins/file/upload?path=exist/evidence/files/`,
      uploadHeaders:{ 
        'Authorization': this.$store.getters.token,
        'Product-Mode': "READ",
        'Terminal-Code': "IPCHAIN2"+ this.$store.getters.terminalCode,
        'Terminal-Type': "WEB",
        'Merchant-Id': "5"
      },
      timeLimit: "1",

      ownershipText: "",
      signatureTypeText: [],
      obtainTypeText: "",
      ownershipChecked: false,
      isOpenViewer: false,

      isShowAgree: false,
      isShowAgree2: false,
      state: false,
      stateNum: 5,

    };
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo","authInfo"]),
  },
  created() {
    this.loadData();
    if(this.form.publishStatus){
      this.publishStatusText = this.constants.publishStatus[this.form.publishStatus * 1 - 1].label;
    }else{
      this.form.publishStatus = '1';
      this.publishStatusText = '未发表';
    }
    this.areaData1 = {
      province: this.form.province,
      city: this.form.city,
    },
    this.areaData2 = {
      province: this.form.province2,
      city: this.form.city2,
    }
    if(!this.form.province){
      this.form.province = '湖北省'
    }
    if(!this.form.city){
      this.form.city = '武汉市'
    }
    if(!this.form.province2){
      this.form.province2 = '湖北省'
    }
    if(!this.form.city2){
      this.form.city2 = '武汉市'
    }

    if(this.form.files){
      let arr = []
      for(let i=0;i<this.form.files.length;i++){
        arr.push({
          name: this.form.files[i].sourceName,
          url: this.form.files[i].downloadUrl,
        })
      }
      this.fileList = arr
    }
  },
  methods: {
    loadData() {
      // queryExistOrderItems().then(({ data }) => {
      //   this.fileList = data;
      // });
      queryObligeeList(1, 100).then(({ data }) => {
        if(data.list.length>0){
          this.obligees = data.list;
          this.form.right = data.list;
          this.form.authors[0].name = data.list[0].userName;
          this.form.authors[0].signatureName = data.list[0].userName;
        }
      });
    },
    calAmount() {
      let amount = 0;
      if(this.form.files.length > 0){
        let registerConfig = this.evidencePaymentPrice.register;
        amount = (registerConfig.amount * 1).toFixed(2);        
      }else{
        amount = '0.00'
      }
      this.form.orderAmount = amount;
    },

    async onSubmit() {
      console.log("onSubmit..");
      try {
        const formBaseResult = await this.$refs["form"].validate();
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        this.mixinsScrollView();
        return;
      }
      this.setTabIdx("tab2");
    },
    onSetAuthorDialogClose() {
      this.isAuthorDialogVisible = false;
    },
    onSetRightDialogClose() {
      this.isRightDialogVisible = false;
    },
    onChooseAuthor(users) {
      this.form.author = users;
    },
    onChooseRight(users) {
      this.form.right = users;
    },
    // 自定义验证
    valiText1(rule, value, callback){
      if(this.form.creationIntention.text == ""){
        callback(new Error("请输入内容简介"));
        return;
      }
      if(this.form.creationIntention.text.length > 50){
        callback(new Error("内容简介不能超过50字"));
        return;
      }
      callback();
    },
    valiText2(rule, value, callback){
      if(this.form.creationOriginality.text == ""){
        callback(new Error("请输入创作过程"));
        return;
      }
      if(this.form.creationOriginality.text.length > 85){
        callback(new Error("创作过程不能超过85字"));
        return;
      }
      callback();
    },
    valiCity(rule, value, callback) {
      if (!this.form.province) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.city) {
        callback(new Error("请选择城市"));
        return;
      }
      // if (!this.form.area) {
      //   callback(new Error("请选择区县"));
      //   return;
      // }
      callback();
    },
    valiCity2(rule, value, callback) {
      if (!this.form.province2) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.city2) {
        callback(new Error("请选择城市"));
        return;
      }
      callback();
    },
    selectArea(data) {
      // console.log(data);
      if(data.laber == 'province'){
        this.form.province = data.value;
      }else if(data.laber == 'city'){
        this.form.city = data.value;
      }
      // console.log(this.form.province);
      // this.form.area = data.selectData.area.name;
    },
    selectArea2(data) {
      if(data.laber == 'province'){
        this.form.province2 = data.value;
      }else if(data.laber == 'city'){
        this.form.city2 = data.value;
      }
      // this.form.area2 = data.selectData.area.name;
    },
    // chooseFile() {
    //   let files = [];
    //   for (let i = 0; i < this.form.evidenceFile.length; i++) {
    //     const element = this.form.evidenceFile[i];
    //     files.push(this.fileList[element * 1]);
    //   }
    //   this.form.files = files;
    //   this.calAmount();
    // },

    // 选择作品类型
    selectWorksType(index){
      this.worksTypeText = this.constants.worksType[index].label;
      this.form.worksType = this.constants.worksType[index].value;
      console.log(this.form.worksType);
    },

    // 选择作品创作性质
    selectCreationNature(index){
      this.creationNatureText = this.constants.creationNature[index].label;
      this.form.creationNature = this.constants.creationNature[index].value;
    },

    // 第三步
    selectPublishStatus(index){
      this.publishStatusText = this.constants.publishStatus[index].label;
      this.form.publishStatus = this.constants.publishStatus[index].value;
    },

    // 第四步
    uploadBefore(file) {
        // console.log(file);
        let fileNameText = file.name.substring(0,file.name.lastIndexOf('.'));
        if(fileNameText != this.form.subject){
          this.$message.error('文件名称必须与作品名称保持一致!');
          return false;
        }
        const NoPassFileTypes=['.exe','.dmg','.js','.html','.json','.xml','.dtd','.jsp']
        const fileName=file.name
        const fileEnd=fileName.substring(fileName.indexOf("."))
				const isFileTypePass = !NoPassFileTypes.includes(fileEnd)
				const isLt500M = file.size / 1024 / 1024 < 500;

				if (!isFileTypePass) {
					this.$message.error('上传文件格式不能为'+fileEnd);
				}

				if (!isLt500M) {
					this.$message.error('上传头像图片大小不能超过 100MB!');
				}
				return isFileTypePass&&isLt500M;
			},
    uploadSuccess(response, file, fileList){
        console.log(arguments)
        this.$refs.uploader.clearValidate()
        this.form.files.push(response);
        if(response){
          this.calAmount();
        }
    },
    handleRemove(file,fileList){
      let that=this;
      for(let i=0;i<this.form.files.length;i++){
        if(file.response.sourceName == this.form.files[i].sourceName){
          // console.log("i-------",i);
          that.form.files.splice(i,1);
          // 更新价格
          this.calAmount();
        }
      }
      // let response=file.response;
      // if(response){
      //   let index=that.form.files.findIndex(t=>{
      //     return t.name==file.name;
      //   });
      //   console.log("index-----",index);
      //   if(index>-1){
      //     that.form.files.splice(index,1);
      //   }
      // }
      // console.log(file,fileList);
    },
    handleExceed(){  //超过上传文件数量限制
      this.$message.error('只能上传一个文件');
    },

    // 第五步
    chooseSignatureType(index,typeIndex) {
      // console.log("选择权署名");
      // console.log("index",index);
      // console.log("typeIndex",typeIndex);
      this.signatureTypeText[typeIndex] = this.constants.signatureType[typeIndex].label
      this.$set(this.form.authors, index, {
          ...this.form.authors[index],
          signatureType: this.constants.signatureType[typeIndex].value,
      })
      
    },

    addAuthors(index){
      this.form.authors.push({"name":"","signatureType":"0","signatureName":"","orders":index+2})
      this.signatureTypeText[index+1] = ''
      // console.log("作者：",this.form.authors);
    },
    delAuthors(index){
      this.form.authors.splice(index,1)
    },

    // 勾选权利保证书，生成保证书图片
    ownershipChange(){
      if(this.form.subject == ''){
        this.$message.error('请先输入登记名称');
        this.ownershipChecked = false;
        return false;
      }
      if(this.ownershipChecked == true){
        let signature = '';
        queryObligeeList(1, 1).then(({data}) => {
						// console.log('--------',data.list.length);
						if (data && data.list.length > 0) {
							console.log('56565',data.list[0]);
              if(data.list[0].obligeeType == '1'){
                signature = data.list[0].userName;
              }else if(data.list[0].obligeeType == '2'){
                signature = data.list[0].companyName;
              }
              console.log('signature',signature);
              this.signature = signature;
              let param = {
                "signature": this.signature,
                "subject": this.form.subject,
              }
              queryEvidenceRight(param).then(({ data }) => {
                // console.log('*--------------***-**',data);
                this.form.supportCode = data.supportCode;
                this.form.ownershipPicture.additionalProp4 = data.supportInfo.jpgUrl;
                this.ownershipCheckedStatus = true;
              });
						}
				})
        // console.log('signature',signature);
        // for(let i in this.form.right){
        //   // console.log('type',this.form.right[i].obligeeType);
        //   if(this.form.right[i].obligeeType == '1'){
        //     //  个人著作权人
        //     signature += this.form.right[i].userName + ","
        //   }else{
        //     // 企业著作权人
        //     signature += this.form.right[i].companyName + ","
        //   }
        // }
        // this.signature = signature.substring(0, signature.lastIndexOf(','));
        // // console.log(this.signature);
        // let param = {
        //   "signature": this.signature,
        //   "subject": this.form.subject,
        // }
        // queryEvidenceRight(param).then(({ data }) => {
        //   // console.log('*--------------***-**',data);
        //   this.form.supportCode = data.supportCode;
        //   this.form.ownershipPicture.additionalProp4 = data.supportInfo.jpgUrl;
        //   this.ownershipCheckedStatus = true;
        // });
      }
    },
    // 查看快照
    openViewer() {
      this.isOpenViewer = true
    },
    // 关闭图片预览
    closeViewer() {
      this.isOpenViewer = false
    },

    openViewer2() {
      this.isShowAgree2 = true
      if (!this.state) {
        // 执行网络请求
        this.state = true
        this.stateNum = 5
        let timer = setInterval(() => {
          this.stateNum--
          if (this.stateNum === 0) {
            clearInterval(timer)
            this.state = false
          }
        }, 1000)
      }
    },
    closeShowAgree2(){
      this.isShowAgree2 = false
    },

    valiIcon1(rule, value, callback){
					if (!this.form.ownershipPicture.additionalProp1) {
					  callback(new Error('请上传权利取得方式证明材料'));
					  return; 
					}
					callback();
		},
    valiIcon3(rule, value, callback){
					if (!this.form.ownershipPicture.additionalProp3) {
					  callback(new Error('请上传权利归属证明材料'));
					  return; 
					}
					callback();
		},
    valiIcon4(rule, value, callback){
					if (!this.ownershipChecked) {
					  callback(new Error('请勾选权利保证书'));
					  return;
					}
					callback();
		},
    async showAgree(){
      try {
        const formBaseResult = await this.$refs["form"].validate();
        if (!this.ownershipCheckedStatus) {
          this.$message({
            type: 'warning',
            message: '请稍等,正在生成权利保证书！'
          });
          return;
        }
      } catch (error) {
        // 有错误,定位到错误
        // eslint-disable-next-line
        console.error(error);
        this.mixinsScrollView();
        return;
      }

      this.isShowAgree = true;
      if (!this.state) {
        // 执行网络请求
        this.state = true
        this.stateNum = 5
        let timer = setInterval(() => {
          this.stateNum--
          if (this.stateNum === 0) {
            clearInterval(timer)
            this.state = false
          }
        }, 1000)
      }
    },
    next(){
        this.isShowAgree = false;
        this.state = false;

        // console.log("lastform:",this.lastform);
        console.log(this.form);
        // this.$emit('getnext',6,this.form)
        // this.step = 6
        this.getnext(6,this.form)
    },



    getnext(step, data) {
      console.log("当前表单：",step, data);
      this.step = step;
      if(data) {
        this.form = Object.assign(this.form,data);
        console.log("所有表单：",this.form)
      }
    }



  },
  // beforeRouteLeave(to, from, next) {
  //   this.$confirm("系统可能不会保存您所做的更改。", "离开此页面？", {
  //     confirmButtonText: "离开",
  //     cancelButtonText: "取消",
  //     type: "warning"
  //   })
  //     .then(() => {
  //       // confirmButton回调
  //       next()
  //     })
  //     .catch(() => {
  //       // cancelButton回调，把当前页路由推回
  //       // 不能使用this.$router进行路由操作，会触发beforeRouteLeave导致死循环
  //       window.history.pushState("", "", this.currentUrl)
  //     })
  // },
};
</script>

<style lang='scss' scoped>
@import "../../../assets/css/inputForm.scss";
.commonWhiteBg {
  min-height: 800px;
  /deep/ .ant-popover-arrow {
    left: 30%;
  }
}
/deep/ .el-textarea__inner{
  height: 120px;
}
.ownershipRow {
  margin-bottom: 0;
}
.ownershipWrap {
  position: relative;
  padding-top: 40px;
  /deep/ .el-radio-group {
    position: absolute;
    width: 200%;
    left: 0;
    top: 11px;
  }
  .tips {
    margin-top: 0;
    .red {
      color: #ff4d4f;
      vertical-align: middle;
    }
    .description{
      .blue{
        color: #007DFF;
        vertical-align: middle;
      }
    }
  }
}
// .tips {
//   margin-top: 20px;
// }
.tips {
  // position: initial;
  position: absolute;
    width: 384px;
    right: -410px;
    top: 0;
}
.signatureName {
  .el-select,
  .el-input {
    width: calc(110% / 2.4);
  }
  .el-select {
    margin-right: calc(20% / 2.4);
  }
}
.Uploader2 {
  font-size: 14px;
  height: 100%;
  text-align: center;
  background: #f9fafc;
  border-radius: 4px;
  border: 1px solid #c0ccda;
}
/deep/ .submitRow .el-form-item__content {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uploadBusinessLicense a{color: #007DFF;}
.MainStepsForm .submitRow button {
  width: 46% !important;
  margin-top: 80px!important;
}

.stepTitle{
  width: 100%;
  margin: 50px 0 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stepTitle li {
  padding: 0 10px;
}
.stepTitle li span{
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(0, 18, 22, 0.2);
  font-size: 16px;
  color: rgba(0, 18, 22, 0.2);

}
.stepTitle li p{
  color: rgba(0, 18, 22, 0.45);
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
}
.stepTitle li.active span{
  border-color: #007DFF;
  color: #fff;
  background: #007DFF;
}
.stepTitle li.active p{
  color: #007DFF;
}

.agree{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  z-index: 99;
  .agreeBox{
    width: 800px;
    height: 600px;
    background: #FFFFFF;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -400px;
    margin-top: -300px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 0 16px rgba(0, 109, 117, 0.06);
    padding: 50px 80px;
    h2{
      font-size: 22pt;
      line-height: 30pt;
      margin-bottom: 30pt;
    }
    p{
      text-align: left;
      padding: 0px;
      margin: 20pt 0;
      text-indent: 0pt;
      line-height: 30pt;
      font-size: 16pt;
      font-family: '楷体_GB2312';
    }
    p:nth-child(3){
      font-size: 14pt;
    }
    button{
      margin-top: 60px;
      width: 30%;
    }
  }
}

.el-radio-group{display: flex;align-items: center;}
.el-radio {
    color: #606266;
    font-weight: 500;
    cursor: pointer;
    margin-right: 30px!important;
    margin-bottom: 0!important;
}
.choosePayStyle /deep/.el-radio__label{border: none!important;}
.amount p{
    color: #316EF4;
    font-size: 22px;
}

@media screen and (max-width: 991px){
  .tips{display: none;}
  .registerFormWarp /deep/.el-form-item__label{width: 120px!important;padding-right: 12px!important;font-size: 14px!important;}
  .registerFormWarp /deep/.el-form-item__content{margin-left: 120px!important;}
  .MainStepsForm .commonWhiteBg{padding: 20px;}
  .agree .agreeBox{width: 90%;margin-left: -45%;padding: 20px;height: 460px;margin-top: -230px;}
  .agree .agreeBox p{font-size: 14px;margin: 10px 0;}
  .agree .agreeBox button{margin-top: 30px;}
  .el-radio-group{display: block;}
}

</style>
